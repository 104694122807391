<template>
    <div class="section" key="post">
        <div class="section-title">
            <div class="author"><img width="50" height="50" src="@/assets/images/rabier.png"></div>
            <h3>Haben Sie bereits Post von einer Behörde erhalten?</h3>
            <p>Dies kann eine Anhörung, ein Bußgeldbescheid, Zeugenfragebogen oder Strafbefehl sein.</p>
        </div>
        <div class="form-container">
            <div class="form-card">
            <input type="radio" v-model="user.postFromOffice" name="radio-2" value="Ja" id="question-4-1">
            <label for="question-4-1" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-checked.svg"></span><span>Ja habe ich</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.postFromOffice" name="radio-2" value="Nein" id="question-4-2">
            <label for="question-4-2" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-cancel.svg"></span><span>Nein habe ich nicht</span></label>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'PostFromOffice',
  computed: mapState({
    user: (state) => state.user,
    checked: (state) => state.checked
  }),
  methods: {
    ...mapMutations([
      'incrementChecked'
    ]),
  }
}
</script>